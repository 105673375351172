import React, { useState, useEffect } from 'react'
import {
  Box,
  Text,
  Flex,
  Button,
  Grid,
  Checkbox,
  IconButton,
  useToast,
  Divider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Select,
  NumberInput,
  NumberInputField,
  Image,
  InputGroup,
  InputRightElement,
  Switch
} from '@chakra-ui/react'
import DefaultLayout from '../components/layouts/DefaultLayout'
import CheckAuth from '../components/layouts/CheckAuth'
import { useRecoilState } from 'recoil'
import { Account, authState } from '../atoms/auth'
import { useNavigate } from 'react-router-dom'
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  format
} from 'date-fns'
import { FaSignOutAlt } from 'react-icons/fa'
import { Select as ChakraSelect } from 'chakra-react-select'
import {
  addAccount,
  deleteAccount,
  getTaskListForUser,
  getTimerList,
  jump,
  saveAccount,
  saveTimer,
  signOut
} from '../services/apiService'
import { useUpdateAuthState } from '../utils/authUtils'
import { set } from 'lodash'
import { DeleteIcon } from '@chakra-ui/icons'

const options = [
  {
    value: '001',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/001.png" alt="밤의민족" boxSize="20px" mr={2} />
        <Text>밤의민족</Text>
      </Flex>
    )
  },
  {
    value: '002',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/002.png" alt="오피가이드" boxSize="20px" mr={2} />
        <Text>오피가이드</Text>
      </Flex>
    )
  },
  {
    value: '003',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/003.ico" alt="외로운밤" boxSize="20px" mr={2} />
        <Text>외로운밤</Text>
      </Flex>
    )
  },
  {
    value: '004',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/004.ico" alt="vip인포" boxSize="20px" mr={2} />
        <Text>vip인포</Text>
      </Flex>
    )
  },
  {
    value: '005',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/005.png" alt="오피뷰" boxSize="20px" mr={2} />
        <Text>오피뷰</Text>
      </Flex>
    )
  },
  {
    value: '006',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/006.ico" alt="오피스타" boxSize="20px" mr={2} />
        <Text>오피스타</Text>
      </Flex>
    )
  },
  {
    value: '007',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/007.png" alt="오피러브" boxSize="20px" mr={2} />
        <Text>오피러브</Text>
      </Flex>
    )
  },
  {
    value: '008',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/008.png" alt="헬로밤" boxSize="20px" mr={2} />
        <Text>헬로밤</Text>
      </Flex>
    )
  },
  // {
  //   value: '009',
  //   label: (
  //     <Flex alignItems="center">
  //       <Image src="/shop/009.ico" alt="밤의제국" boxSize="20px" mr={2} />
  //       <Text>밤의제국</Text>
  //     </Flex>
  //   )
  // },
  // {
  //   value: '010',
  //   label: (
  //     <Flex alignItems="center">
  //       <Image src="/shop/010.ico" alt="인천달리기" boxSize="20px" mr={2} />
  //       <Text>인천달리기</Text>
  //     </Flex>
  //   )
  // },
  // {
  //   value: '011',
  //   label: (
  //     <Flex alignItems="center">
  //       <Image src="/shop/011.ico" alt="오피아트" boxSize="20px" mr={2} />
  //       <Text>오피아트</Text>
  //     </Flex>
  //   )
  // },
  {
    value: '012',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/012.png" alt="대한민국달리기" boxSize="20px" mr={2} />
        <Text>대한민국달리기</Text>
      </Flex>
    )
  },
  {
    value: '013',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/013.ico" alt="마사지몬" boxSize="20px" mr={2} />
        <Text>마사지몬</Text>
      </Flex>
    )
  },
  // {
  //   value: '014',
  //   label: (
  //     <Flex alignItems="center">
  //       <Image src="/shop/014.ico" alt="여긴어때" boxSize="20px" mr={2} />
  //       <Text>여긴어때</Text>
  //     </Flex>
  //   )
  // },
  {
    value: '015',
    label: (
      <Flex alignItems="center">
        <Image src="/shop/015.ico" alt="즐거운마사지" boxSize="20px" mr={2} />
        <Text>즐거운마사지</Text>
      </Flex>
    )
  }
]

const CustomSelect = ({ value, onChange }: any) => {
  return (
    <ChakraSelect
      options={options}
      placeholder="사이트를 선택하세요"
      value={options.find((option) => option.value === value)}
      onChange={(option: any) =>
        onChange({ target: { name: 'site', value: option.value } })
      }
    />
  )
}

const App = () => {
  const navigate = useNavigate()
  const toast = useToast()
  const [auth, setAuth] = useRecoilState(authState)
  const updateAuthState = useUpdateAuthState()

  const [isTelegramModalOpen, setIsTelegramModalOpen] = useState(false)

  // 데이터 리스트를 관리하는 state
  const [dataList, setDataList] = useState(
    auth.accountList.map((account) => ({ ...account, isSelected: false }))
  )

  // 태스크 이력
  const [taskList, setTaskList] = useState([])

  // 타이머
  const [timerList, setTimerList] = useState<any>([
    {
      idx: 1,
      value: '',
      onetime: false,
      action: 'ADD'
    }
  ])

  const [selectLinkId, setSelectLinkId] = useState(0)

  // 전체 선택 상태를 관리하는 state
  const [isAllSelected, setIsAllSelected] = useState(false)

  // 모달 관련 state
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isSettingModalOpen, setIsSettingModalOpen] = useState(false)
  const [isTimerModalOpen, setIsTimerModalOpen] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<any>(null)
  const [isDeleteConfirmModalOpen, setIsDeleteConfirmModalOpen] =
    useState(false)

  const [newAccount, setNewAccount] = useState({
    site: '001',
    id: '',
    password: '',
    jump: 1
  })

  // 로딩 상태를 관리하는 state
  const [isJumping, setIsJumping] = useState(false)

  // 선택된 항목의 수 계산
  const selectedCount = dataList.filter((item) => item.isSelected).length

  // 추가된 부분: 삭제하려는 계정 정보를 저장하는 상태 관리
  const [accountToDelete, setAccountToDelete] = useState<any>(null)

  // 체크박스 선택 상태를 변경하는 핸들러
  const handleCheckboxChange = (id: number) => {
    setDataList((prevDataList) =>
      prevDataList.map((item) =>
        item.id === id ? { ...item, isSelected: !item.isSelected } : item
      )
    )
  }

  // 전체 선택/해제 핸들러
  const handleSelectAllChange = () => {
    const newIsAllSelected = !isAllSelected
    setIsAllSelected(newIsAllSelected)
    setDataList((prevDataList) =>
      prevDataList.map((item) => ({
        ...item,
        isSelected: newIsAllSelected
      }))
    )
  }

  // 고객센터 클릭 핸들러
  const handleCustomerServiceClick = () => {
    // window.open('https://t.me/xxavisjump', '_blank')
    setIsTelegramModalOpen(true)
  }

  // 로그아웃 클릭 핸들러
  const handleLogoutClick = async () => {
    try {
      const { data: res } = await signOut()
      navigate('/') // 로그인 페이지로 리디렉션
      toast({
        title: '로그아웃',
        description: '정상적으로 로그아웃 되었습니다.',
        status: 'success',
        duration: 3000,
        isClosable: true
      })
    } catch (err) {
      //
    }
  }

  // 모달 열기
  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  // 모달 닫기
  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  // 모달 폼 데이터 핸들러
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target
    setNewAccount((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { name, value } = e.target
    setNewAccount((prev) => ({
      ...prev,
      [name]: value
    }))
  }

  const handleNumberChange = (valueAsString: string, valueAsNumber: number) => {
    setNewAccount((prev) => ({
      ...prev,
      jump: valueAsNumber
    }))
  }

  const handleSelectedAccountInputChange = (key: any, value: any) => {
    console.log(key)
    console.log(value)
    console.log(selectedAccount)

    if (key === 'site') {
      setSelectedAccount((prev: any) => ({
        ...prev,
        scriptNo: value
      }))
    } else if (key === 'account') {
      setSelectedAccount((prev: any) => ({
        ...prev,
        account: value
      }))
    } else if (key === 'password') {
      setSelectedAccount((prev: any) => ({
        ...prev,
        password: value
      }))
    } else if (key === 'jump') {
      console.log(isNaN(value))
      if (isNaN(value)) {
        setSelectedAccount((prev: any) => ({
          ...prev,
          jumpCount: 0
        }))
      } else {
        setSelectedAccount((prev: any) => ({
          ...prev,
          jumpCount: value
        }))
      }
    }
  }

  // 모달에서 추가 버튼 클릭 핸들러
  const handleAddAccount = async () => {
    try {
      const { data: res } = await addAccount({
        scriptNo: newAccount.site,
        account: newAccount.id,
        password: newAccount.password,
        jumpCount: newAccount.jump
      })
      if (res.statusCode === 200) {
        // form 초기화
        setNewAccount({
          site: '001',
          id: '',
          password: '',
          jump: 1
        })

        updateAuthState() // 계정 추가 후 auth state 업데이트

        setIsAllSelected(false)

        handleCloseModal()

        toast({
          title: '계정 추가',
          description: '계정이 성공적으로 추가되었습니다.',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      }
    } catch (err: any) {
      toast({
        title: '실패',
        description: err.response.data.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  // 모달에서 저장 버튼 클릭 핸들러
  const handleSaveAccount = async () => {
    try {
      const { data: res } = await saveAccount({
        id: selectedAccount.id,
        scriptNo: selectedAccount.scriptNo,
        account: selectedAccount.account,
        password: selectedAccount.password,
        jumpCount: selectedAccount.jumpCount
      })
      if (res.statusCode === 200) {
        updateAuthState() // 계정 추가 후 auth state 업데이트

        setIsAllSelected(false)

        setIsSettingModalOpen(false)

        toast({
          title: '계정 저장',
          description: '계정이 성공적으로 저장되었습니다.',
          status: 'success',
          duration: 3000,
          isClosable: true
        })
      }
    } catch (err: any) {
      toast({
        title: '실패',
        description: err.response.data.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  // 선택점프
  const handleJumpClick = async () => {
    if (selectedCount === 0) {
      toast({
        title: '선택점프',
        description: '점프할 계정을 선택해주세요.',
        status: 'warning',
        duration: 1000,
        isClosable: true
      })
      return
    }

    setIsJumping(true) // 로딩 상태 시작

    const selectedAccounts = dataList.filter((item) => item.isSelected)

    for (let i = 0; i < selectedAccounts.length; i++) {
      const item = selectedAccounts[i]

      if (item.jumpCount === 0) {
        continue
      }

      try {
        const { data: res } = await jump({
          id: item.id,
          domainId: item.domainId,
          account: item.account,
          password: item.password
        })
        if (res.statusCode === 200) {
          toast({
            title: '선택점프',
            description: '점프가 성공적으로 완료되었습니다.',
            status: 'success',
            duration: 1000,
            isClosable: true
          })

          // 선택 전체 해제
          setIsAllSelected(false)

          // 각 아이템도 선택 해제
          setDataList((prevDataList) =>
            prevDataList.map((item) => ({
              ...item,
              isSelected: false
            }))
          )
        }
      } catch (err: any) {
        toast({
          title: '실패',
          description: err.response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }

    // 이력 새로고침
    initData()

    // 사용자
    updateAuthState()

    setIsJumping(false) // 로딩 상태 종료
  }

  // 전체점프
  const handleAllJumpClick = async () => {
    if (dataList.length === 0) {
      toast({
        title: '전체점프',
        description: '점프할 계정이 없습니다.',
        status: 'warning',
        duration: 1000,
        isClosable: true
      })
      return
    }

    setIsJumping(true) // 로딩 상태 시작

    for (let i = 0; i < dataList.length; i++) {
      const item = dataList[i]

      if (item.jumpCount === 0) {
        continue
      }

      try {
        const { data: res } = await jump({
          id: item.id,
          domainId: item.domainId,
          account: item.account,
          password: item.password
        })
        if (res.statusCode === 200) {
          toast({
            title: '선택점프',
            description: '점프가 성공적으로 완료되었습니다.',
            status: 'success',
            duration: 1000,
            isClosable: true
          })

          // 선택 전체 해제
          setIsAllSelected(false)

          // 각 아이템도 선택 해제
          setDataList((prevDataList) =>
            prevDataList.map((item) => ({
              ...item,
              isSelected: false
            }))
          )
        }
      } catch (err: any) {
        toast({
          title: '실패',
          description: err.response.data.message,
          status: 'error',
          duration: 3000,
          isClosable: true
        })
      }
    }

    // 이력 새로고침
    initData()

    // 사용자
    updateAuthState()

    setIsJumping(false) // 로딩 상태 종료
  }

  // 설정 클릭 핸들러
  const handleSettingClick = (item: any) => {
    console.log('handleSettingClick', item)
    setSelectedAccount(item)
    setIsSettingModalOpen(true)
  }

  // 타이머 추가
  const handleClickAddTimer = () => {
    setTimerList((prev: any) => [
      ...prev,
      {
        idx: prev.length + 1,
        value: '',
        onetime: false,
        action: 'ADD'
      }
    ])
  }

  // 타이머 삭제
  const handleClickDeleteTimer = (idx: number) => {
    console.log('idx => ', idx)

    setTimerList((prevTimerList: any) => {
      return prevTimerList
        .map((item: any) => {
          if (item.idx === idx) {
            if (item.action === 'ADD') {
              // ADD 상태인 경우 아이템을 삭제
              return null
            } else if (item.action === 'EDIT') {
              // EDIT 상태인 경우 action을 DELETE로 변경
              return { ...item, action: 'DELETE' }
            } else if (item.action === 'DELETE') {
              // DELETE 상태인 경우 action을 EDIT으로 변경
              return { ...item, action: 'EDIT' }
            }
          }
          return item
        })
        .filter((item: any) => item !== null) // null 값 필터링하여 최종 배열 구성
    })
  }

  // 타이머 값 변경
  const handleChangeValue = (idx: number, newValue: string) => {
    // 숫자만 입력 가능하도록 필터링
    const filteredValue = newValue.replace(/\D/g, '') // 숫자 이외의 문자 제거

    // 5자리 이상 입력되지 않도록 제한
    if (filteredValue.length > 4) {
      return // 5자리 이상인 경우 입력을 무시
    }

    // 4자리일 때 HH:MM 형식으로 변환
    let formattedValue = filteredValue
    if (filteredValue.length === 4) {
      formattedValue = `${filteredValue.slice(0, 2)}:${filteredValue.slice(
        2,
        4
      )}`

      // HH:MM 형식 검증 (00:00 ~ 23:59 범위)
      const [hours, minutes] = formattedValue.split(':').map(Number)
      if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
        console.log('유효하지 않은 시간 범위입니다.')
        return // 유효하지 않은 경우 업데이트하지 않음
      }
    }

    // 상태 업데이트
    setTimerList((prevTimerList: any) =>
      prevTimerList.map((item: any) => {
        if (item.idx === idx) {
          return { ...item, value: formattedValue }
        }
        return item
      })
    )
  }

  // 타이머 토글 변경
  const handleToggleOnetime = (idx: number, isChecked: boolean) => {
    console.log('idx => ', idx)
    console.log('isChecked => ', isChecked)
    setTimerList((prevTimerList: any) =>
      prevTimerList.map((item: any) => {
        if (item.idx === idx) {
          return { ...item, onetime: isChecked }
        }
        return item
      })
    )
  }

  // 타이머 저장
  const handleSaveTimer = async () => {
    console.log('timerList => ', timerList)

    // value가 ''인 객체가 있는지 확인
    const hasEmptyValue = timerList.some((item: any) => item.value === '')

    if (hasEmptyValue) {
      toast({
        title: '실패',
        description: '시간 입력이 되지 않은 타이머가 존재합니다.',
        status: 'warning',
        duration: 1000,
        isClosable: true
      })
      return // 빈 값이 있을 경우 저장을 중단
    }

    // 동일한 value를 가진 객체가 있는지 확인
    const hasDuplicateValue = timerList.some(
      (item: any, index: number, array: any[]) =>
        array.filter((i) => i.value === item.value).length > 1
    )

    if (hasDuplicateValue) {
      toast({
        title: '실패',
        description: '중복된 시간이 있는 타이머가 존재합니다.',
        status: 'warning',
        duration: 1000,
        isClosable: true
      })
      return // 중복된 값이 있을 경우 저장을 중단
    }

    try {
      const { data: res } = await saveTimer({
        timerList,
        selectLinkId
      })
      if (res.statusCode === 200) {
        toast({
          title: '타이머 저장',
          description: '타이머가 성공적으로 저장되었습니다.',
          status: 'success',
          duration: 1000,
          isClosable: true
        })

        setIsTimerModalOpen(false)
      }
    } catch (err: any) {
      toast({
        title: '실패',
        description: err.response.data.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  // 타이머 클릭 핸들러
  const handleTimerClick = async (item: any) => {
    const linkId: number = item.id
    console.log('linkId => ', linkId)

    setSelectLinkId(linkId)
    setSelectedAccount(item)

    try {
      const { data: res } = await getTimerList({ id: linkId })
      if (res.statusCode === 200) {
        console.log('res.data => ', res.data)

        const tList = res.data

        const transformedList = tList.map((item: any, index: number) => ({
          idx: index + 1,
          id: item.id,
          value: item.autoExe.substring(0, 5),
          onetime: item.onetime === 1, // 1이면 true, 0이면 false
          action: 'EDIT'
        }))

        if (tList.length === 0) {
          setTimerList([
            {
              idx: 1,
              value: '',
              onetime: false,
              action: 'ADD'
            }
          ])
        } else {
          setTimerList(transformedList)
        }
      }
    } catch (err: any) {
      toast({
        title: '실패',
        description: err.response.data.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }

    setSelectedAccount(item)
    setIsTimerModalOpen(true)
  }

  // 계정삭제 버튼 클릭 핸들러
  const handleDeleteAccountClick = (item: any) => {
    setAccountToDelete(item)
    setIsDeleteConfirmModalOpen(true) // 삭제 확인 모달 열기
  }

  // 삭제 확인 모달 닫기
  const handleCloseDeleteConfirmModal = () => {
    setIsDeleteConfirmModalOpen(false)
    setAccountToDelete(null) // 삭제할 계정 초기화
  }

  // 계정 실제 삭제 핸들러
  const handleConfirmDeleteAccount = async () => {
    try {
      const { data: res } = await deleteAccount({ id: accountToDelete.id })
      if (res.statusCode === 200) {
        updateAuthState() // 삭제 후 auth 상태 업데이트

        toast({
          title: '계정 삭제',
          description: '계정이 성공적으로 삭제되었습니다.',
          status: 'success',
          duration: 3000,
          isClosable: true
        })

        handleCloseDeleteConfirmModal() // 삭제 확인 모달 닫기

        setIsSettingModalOpen(false) // 설정 모달 닫기
      }
    } catch (err: any) {
      toast({
        title: '실패',
        description: err.response.data.message,
        status: 'error',
        duration: 3000,
        isClosable: true
      })
    }
  }

  const initData = async () => {
    try {
      const { data: res } = await getTaskListForUser()
      if (res.statusCode === 200) {
        setTaskList(res.data)
      }
    } catch (err: any) {
      //
    }
  }

  useEffect(() => {
    initData()
  }, [])

  // Update dataList when auth.accountList changes
  useEffect(() => {
    setDataList(
      auth.accountList.map((account) => ({ ...account, isSelected: false }))
    )
  }, [auth.accountList])

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box maxW="md" mx="auto" py={4} pb="80px">
          <Box p={4} bg="gray.800" borderRadius="md">
            <Flex justifyContent="space-between" alignItems="center">
              <Text color="white" fontWeight="bold">
                자비스 JUMP!
              </Text>
              <Box>
                <Flex gap={3} alignItems="center">
                  <Text
                    color="white"
                    fontWeight="bold"
                    onClick={handleCustomerServiceClick}
                    cursor="pointer"
                  >
                    고객센터
                  </Text>
                  <IconButton
                    aria-label="로그아웃"
                    icon={<FaSignOutAlt />}
                    color="white"
                    bg="transparent"
                    _hover={{ bg: 'transparent', color: 'gray.300' }}
                    fontSize="20px"
                    onClick={handleLogoutClick}
                  />
                </Flex>
              </Box>
            </Flex>
            <Text color="gray.400" fontSize="sm">
              {auth.account} 님의 남은 제휴일{' '}
              <Text as="span" color="orange.300">
                {auth?.expiredAt
                  ? differenceInDays(new Date(auth.expiredAt), new Date()) > 0
                    ? `${differenceInDays(
                        new Date(auth.expiredAt),
                        new Date()
                      )}일`
                    : `${differenceInHours(
                        new Date(auth.expiredAt),
                        new Date()
                      )}시간 ${
                        differenceInMinutes(
                          new Date(auth.expiredAt),
                          new Date()
                        ) % 60
                      }분`
                  : '0일'}
              </Text>
            </Text>
          </Box>

          <Box>
            <Flex justifyContent="space-between" alignItems="start">
              <Text color="white" fontWeight="bold" mt={3}>
                계정 목록 [{auth.accountList.length} / {auth.serviceCnt}]
              </Text>
              <Button
                colorScheme="blue"
                size="sm"
                mt={2}
                mb={4}
                onClick={handleOpenModal}
              >
                계정추가
              </Button>
            </Flex>
          </Box>

          <Box p={4} bg="gray.800" borderRadius="md" mt={-1}>
            <Grid templateColumns="1fr 2fr 1fr 1fr 1fr" gap={2}>
              <Checkbox
                colorScheme="orange"
                isChecked={isAllSelected}
                onChange={handleSelectAllChange}
              />
              <Text fontSize={'xs'} color="gray.400">
                사이트
              </Text>
              <Text fontSize={'xs'} color="gray.400">
                점프
              </Text>
              <Text fontSize={'xs'} color="gray.400">
                타이머
              </Text>
              <Text fontSize={'xs'} color="gray.400">
                설정
              </Text>
            </Grid>

            {dataList.length === 0 && (
              <Box mt={8} textAlign="center">
                <Text color="gray.400">현재 등록된 계정이 없습니다.</Text>
                <Text color="gray.400">
                  '계정추가' 버튼을 눌러 계정을 등록해보세요
                </Text>
              </Box>
            )}

            {dataList.map((item: any, idx: number) => (
              <>
                <Grid
                  templateColumns="1fr 2fr 1fr 1fr 1fr"
                  gap={2}
                  mt={2}
                  key={item.id}
                >
                  <Checkbox
                    colorScheme="orange"
                    isChecked={item.isSelected}
                    onChange={() => handleCheckboxChange(item.id)}
                    disabled={item.jumpCount === 0}
                  />
                  <Text fontSize={'xs'} color="white">
                    {item.siteName}
                  </Text>
                  <Text color="blue.400">{item.jumpCount}</Text>
                  <Text color="gray.400">
                    <Button
                      colorScheme="teal"
                      size="sm"
                      onClick={() => handleTimerClick(item)}
                    >
                      타이머
                    </Button>
                  </Text>
                  <Text color="gray.400">
                    <Button
                      colorScheme="teal"
                      size="sm"
                      onClick={() => handleSettingClick(item)}
                    >
                      설정
                    </Button>
                  </Text>
                </Grid>
                <Box color={'#a7a7a7'} mt={1}>
                  <Flex justifyContent={'center'} columnGap={3}>
                    <Text fontSize={'xs'}>id [{item.account}]</Text>
                    <Text fontSize={'xs'}>pw [{item.password}]</Text>
                    <Text fontSize={'xs'}>timer [{item.autoExeCount}]</Text>
                  </Flex>
                </Box>
                {dataList.length !== idx + 1 && (
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                )}
              </>
            ))}
          </Box>

          <Box mt={2} p={2}>
            <Button
              colorScheme={selectedCount === 0 ? 'gray' : 'purple'}
              w="full"
              disabled={selectedCount === 0 || isJumping}
              isLoading={isJumping}
              onClick={() => handleJumpClick()}
            >
              {selectedCount > 0
                ? `${selectedCount}개의 계정을 점프`
                : '선택 점프'}
            </Button>
          </Box>

          <Box>
            <Flex justifyContent="space-between" alignItems="start">
              <Text color="white" fontWeight="bold" mt={3}>
                최근점프
              </Text>
              <Button
                colorScheme="blue"
                size="sm"
                mt={2}
                mb={4}
                onClick={() => {
                  initData()

                  toast({
                    title: '새로고침',
                    description: '최근점프 이력을 새로 가져왔습니다.',
                    status: 'info',
                    duration: 1000,
                    isClosable: true
                  })
                }}
              >
                새로고침
              </Button>
            </Flex>
          </Box>

          <Box p={4} bg="gray.800" borderRadius="md" mt={-1}>
            <Grid templateColumns="2fr 2fr 1.5fr 1.5fr" gap={2}>
              <Text fontSize={'xs'} color="gray.400">
                사이트
              </Text>
              <Text fontSize={'xs'} color="gray.400">
                상태
              </Text>
              <Text fontSize={'xs'} color="gray.400">
                아이디
              </Text>
              <Text fontSize={'xs'} color="gray.400">
                비밀번호
              </Text>
            </Grid>

            {taskList.length === 0 && (
              <Box mt={8} textAlign="center">
                <Text color="gray.400">최근 점프 이력이 없습니다.</Text>
                <Text color="gray.400">
                  선택점프 혹은 전체 점프를 해보세요!
                </Text>
              </Box>
            )}

            {taskList.map((item: any, idx: number) => (
              <>
                <Grid
                  templateColumns="2fr 2fr 1.5fr 1.5fr"
                  gap={2}
                  mt={2}
                  key={item.id}
                >
                  <Text fontSize={'xs'} color="white">
                    {item.siteName}
                  </Text>

                  {item.result === 0 && (
                    <Text fontSize={'xs'} color="gray.400">
                      대기
                    </Text>
                  )}
                  {item.result === 1 && (
                    <Text fontSize={'xs'} color="blue.400">
                      진행중
                    </Text>
                  )}
                  {item.result === 2 && (
                    <Text fontSize={'xs'} color="green.400">
                      성공
                    </Text>
                  )}
                  {item.result === 9 && (
                    <Text fontSize={'xs'} color="red.400">
                      실패
                    </Text>
                  )}

                  <Text fontSize={'xs'}>{item.account}</Text>
                  <Text fontSize={'xs'}>{item.password}</Text>
                </Grid>
                <Flex columnGap={3} justifyContent={'end'} color="#d0d0d0">
                  <Text fontSize={'xx-small'}>[001] Script 001 시작</Text>
                  <Text fontSize={'xx-small'}>
                    {format(item.createdAt, 'yyyy.MM.dd HH:mm')}
                  </Text>
                </Flex>
                {dataList.length !== idx + 1 && (
                  <Box mt={2} mb={2}>
                    <Divider />
                  </Box>
                )}
              </>
            ))}
          </Box>

          <Box
            position="fixed"
            bottom="0"
            left="0"
            width="100%"
            bg="#262626"
            zIndex="1000"
            p={4}
          >
            <Button
              colorScheme="blue"
              w="full"
              size="lg"
              onClick={() => handleAllJumpClick()}
              isLoading={isJumping}
            >
              전체 점프
            </Button>
          </Box>
        </Box>

        {/* 계정 추가 모달 */}
        <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>계정 추가</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb={4}>
                <FormLabel>사이트설정</FormLabel>
                <CustomSelect
                  value={newAccount.site}
                  onChange={handleSelectChange}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>아이디</FormLabel>
                <Input
                  name="id"
                  value={newAccount.id}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>비밀번호</FormLabel>
                <Input
                  type="text"
                  name="password"
                  value={newAccount.password}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>일일 점프 개수</FormLabel>
                <NumberInput
                  min={1}
                  value={newAccount.jump}
                  onChange={handleNumberChange}
                >
                  <NumberInputField name="jump" />
                </NumberInput>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={handleCloseModal}>
                취소
              </Button>
              <Button colorScheme="blue" ml={3} onClick={handleAddAccount}>
                추가
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* 설정 모달 */}
        <Modal
          isOpen={isSettingModalOpen}
          onClose={() => setIsSettingModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Flex alignContent={'center'} columnGap={3}>
                <Text>계정 설정</Text>
                <Text fontSize={'xs'} pt={2}>
                  {selectedAccount?.siteName} . {selectedAccount?.account} .{' '}
                  {selectedAccount?.password}
                </Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <FormControl mb={4}>
                <FormLabel>사이트설정</FormLabel>
                <CustomSelect
                  value={selectedAccount?.scriptNo || '001'}
                  onChange={(e: any) =>
                    handleSelectedAccountInputChange('site', e.target.value)
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>아이디</FormLabel>
                <Input
                  name="id"
                  value={selectedAccount?.account || ''}
                  onChange={(e: any) =>
                    handleSelectedAccountInputChange('account', e.target.value)
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>비밀번호</FormLabel>
                <Input
                  type="text"
                  name="password"
                  value={selectedAccount?.password || ''}
                  onChange={(e: any) =>
                    handleSelectedAccountInputChange('password', e.target.value)
                  }
                />
              </FormControl>
              <FormControl mb={4}>
                <FormLabel>일일 점프 개수</FormLabel>
                <NumberInput
                  min={1}
                  value={selectedAccount?.jumpCount}
                  onChange={(e, v: any) =>
                    handleSelectedAccountInputChange('jump', v)
                  }
                >
                  <NumberInputField name="jump" />
                </NumberInput>
                <Box>
                  <Text fontSize={'xs'}>
                    * 사이트에 해당하는 점프값을 입력해주세요.
                  </Text>
                  <Text fontSize={'xs'}>
                    * 일일 점프 개수가 수정되면 점프의 값이 덮어씌어집니다.
                  </Text>
                  <Text fontSize={'xs'}>
                    * 일일 점프 개수는 00:00에 해당 값으로 초기화됩니다.
                  </Text>
                </Box>
              </FormControl>
            </ModalBody>
            <ModalFooter>
              <Flex justifyContent="space-between" width="100%">
                <Button
                  colorScheme="red"
                  variant="ghost"
                  onClick={() => handleDeleteAccountClick(selectedAccount)}
                >
                  계정삭제
                </Button>
                <Flex>
                  <Button
                    variant="ghost"
                    onClick={() => setIsSettingModalOpen(false)}
                  >
                    취소
                  </Button>
                  <Button colorScheme="blue" ml={3} onClick={handleSaveAccount}>
                    저장
                  </Button>
                </Flex>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* 타이머 모달 */}
        <Modal
          isOpen={isTimerModalOpen}
          onClose={() => setIsTimerModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Flex alignContent={'center'} columnGap={3}>
                <Text>타이머 설정</Text>
                <Text fontSize={'xs'} pt={2}>
                  {selectedAccount?.siteName} . {selectedAccount?.account} .{' '}
                  {selectedAccount?.password}
                </Text>
              </Flex>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box></Box>
              <Grid templateColumns="1fr 4fr 2fr 1fr" gap={2}>
                <Text fontSize={'xs'} color="gray.400">
                  번호
                </Text>
                <Text fontSize={'xs'} color="gray.400">
                  시간(HH:MM) <br />
                  00:00 ~ 23:59
                </Text>
                <Text fontSize={'xs'} color="gray.400">
                  1회성 타이머
                </Text>
                <Text fontSize={'xs'} color="gray.400">
                  삭제
                </Text>
              </Grid>

              {timerList.map((item: any, idx: number) => {
                if (item.action === 'DELETE') {
                  return (
                    <>
                      <Flex
                        mb={1}
                        alignItems="center"
                        justifyContent="space-between"
                        columnGap={5}
                        bg={'#ffeded'}
                      >
                        <Text mr={4}>{idx + 1}</Text>
                        <Input
                          type="text"
                          value={item.value}
                          placeholder="HH:MM"
                        />
                        <Switch size="sm" isChecked={item.onetime} />
                        <IconButton
                          aria-label="Delete"
                          icon={<DeleteIcon />}
                          size="sm"
                          mr={2}
                          onClick={() => {
                            handleClickDeleteTimer(item.idx)
                          }}
                        />
                      </Flex>
                    </>
                  )
                }

                return (
                  <>
                    <>
                      <Flex
                        mb={1}
                        alignItems="center"
                        justifyContent="space-between"
                        columnGap={5}
                      >
                        <Text mr={4}>{idx + 1}</Text>
                        <Input
                          type="text"
                          value={item.value}
                          onChange={(e) =>
                            handleChangeValue(item.idx, e.target.value)
                          }
                          placeholder="HH:MM"
                        />
                        <Switch
                          size="sm"
                          isChecked={item.onetime}
                          onChange={(e: any) =>
                            handleToggleOnetime(item.idx, e.target.checked)
                          }
                        />
                        <IconButton
                          aria-label="Delete"
                          icon={<DeleteIcon />}
                          size="sm"
                          mr={2}
                          onClick={() => {
                            handleClickDeleteTimer(item.idx)
                          }}
                        />
                      </Flex>
                    </>
                  </>
                )
              })}

              <Box mt={6}>
                <Button width={'100%'} onClick={() => handleClickAddTimer()}>
                  타이머 추가
                </Button>
              </Box>

              <Box mt={3}>
                <Text fontSize={'xs'}>
                  * 1회성 타이머는 1번 실행 시 타이머에서 삭제됩니다.
                </Text>
                <Text fontSize={'xs'}>
                  * 시간은 24시간제로 입력해주세요. (예: 00:00 ~ 23:59)
                </Text>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Button
                variant="ghost"
                onClick={() => setIsTimerModalOpen(false)}
              >
                닫기
              </Button>
              <Button
                colorScheme="blue"
                ml={3}
                onClick={() => handleSaveTimer()}
              >
                저장
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* 계정 삭제 확인 모달 */}
        <Modal
          isOpen={isDeleteConfirmModalOpen}
          onClose={handleCloseDeleteConfirmModal}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>계정 삭제 확인</ModalHeader>
            <ModalCloseButton />
            <ModalBody>정말로 삭제하시겠습니까?</ModalBody>
            <ModalFooter>
              <Button variant="ghost" onClick={handleCloseDeleteConfirmModal}>
                취소
              </Button>
              <Button
                colorScheme="red"
                onClick={handleConfirmDeleteAccount}
                ml={3}
              >
                삭제
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        {/* 텔래그램 고객센터 */}
        <Modal
          isOpen={isTelegramModalOpen}
          onClose={() => setIsTelegramModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>고객센터</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>
                <Text>
                  Telegram ID: <b>@xxavisjump</b>
                </Text>
                <Text>문의가 있는 경우 텔레그램으로 연락 부탁드립니다.</Text>
              </Text>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={() => setIsTelegramModalOpen(false)}
              >
                닫기
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </DefaultLayout>
    </>
  )
}

export default App
